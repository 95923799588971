#navbar-container {
  /* border: 1px solid orange; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 345.391px;
}

#list-div {
  /* border: 1px solid blue; */
  text-align: center;
  padding: 28px 0;
  width: auto;
}

#navbar-list {
  /* border: 1px solid green; */
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  line-height: 1.5em;
  padding: 0;
}

.navbar-option {
  /* border: 1px solid salmon; */
  color: rgb(107, 107, 107);
  text-decoration: none;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0 0.8em;
}

ul {
  margin: 0;
}

#list-div ul li {
  display: inline-block;
}

.selected-option {
  opacity: 65%;
}
