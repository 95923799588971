* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#img-prevs {
  /* border: 1px solid green; */
  /* columns: 3 250px; */
  /* gap: 15px; */
  margin-bottom: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#gallery-container {
  max-width: 85%;
  margin: 20px auto;
}
