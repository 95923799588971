#header {
  /* border: 1px solid purple; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#header-name {
  text-transform: uppercase;
  font-family: "chelsea-market-pro", sans-serif;
  font-weight: 800;
  font-style: bold;
  letter-spacing: 2.4px;
  color: #d972a1;
  font-size: 20px;
  padding: 10px 0;
}

#header-img {
  /* border: 1px solid #d972a1; */
  border-radius: 60px;
  background-image: url("../../imgs/almis_pfp.png");
  background-size: cover;
  background-position: center;
  background-color: #d972a1;
  width: 120px;
  height: 120px;
  margin-bottom: 25px;
}

#header-titles {
  display: flex;
  justify-content: center;
  /* border: 1px solid skyblue; */
  color: rgb(126, 126, 126);
  text-transform: uppercase;
  width: 100%;
  font-size: 13px;
}

#header-contact {
  /* border: 1px solid green; */
  margin-top: 6px;
  color: rgb(126, 126, 126);
  display: flex;
  justify-content: center;
  font-size: 13.5px;
  width: 100%;
  text-decoration: none;
}
