#about-page {
  display: flex;
  justify-content: center;
}

#about-container {
  max-width: 800px;
}
#about-img {
  /* height: 0; */
  width: 100%;
  /* border: 1px solid orange; */
  /* background-image: url('../../imgs/almira_about.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 65.366541%;
  margin-bottom: 30px; */
  margin-top: 30px;
  margin-bottom: 30px;
}

#about-descr {
  line-height: 1.8;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
}
