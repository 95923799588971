#footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
}

#links {
  display: flex;
  font-size: 36px;
}

#link {
  color: grey;
}

#copyright {
  color: grey;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
