body {
  color: black;
  margin: 0;
  padding: 80px;
}

@media screen and (max-width: 960px) {
  body {
    padding: 50px;
  }
}
